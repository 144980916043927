<template>
  <!-- Butik shop item -->
  <div>
    <Confirm
      :title="$t('COMMON.CONFIRM')"
      :message="$t('PAGES.SHOPITEM.CONFIRM_DELETE')"
      ref="deleteItemConfirm"
      type="success"
      @confirm="on_delete_item_confirm"
    />

    <b-form @submit="update_shop_item" class="mt-8">
      <div class="mt-4 mb-4" v-if="!local_item.is_ticket">
        <b-button type="button" variant="warning" v-if="local_item.shop_item_id" @click="duplicate_to_all_companies">{{$t('PAGES.SHOPITEM.CREATE_FOR_ALL')}}</b-button>
      </div>


      <b-form-group id="input-group-shop_item_id" label="ID" label-for="input-shop_item_id">
        <b-form-input
          id="input-shop_item_id"
          v-model="local_item.shop_item_id"
          type="text"
          disabled
        ></b-form-input>
      </b-form-group>

      <div v-if="isTHS" class="d-flex align-items-center mt-6">
        <label class="checkbox checkbox-outline checkbox-success">
          <input type="checkbox" name="is_shared" v-model="local_item.is_shared" />
          <span></span>
        </label>
        <span class="ml-3 cursor-pointer">{{ $t('PAGES.SHOPITEM.IS_SHARED') }}</span>
      </div>

      <h5 class="mb-8 mt-8">{{$t('PAGES.SHOPITEM.PRESENTATION')}}</h5>
      <b-form-group id="input-group-name" :label="$t('PAGES.SHOPITEM.NAME')" label-for="input-name">
        <b-form-input id="input-name" v-model="local_item.name" type="text"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-descr" :label="$t('PAGES.SHOPITEM.DESCR')" label-for="input-descr">
        <b-form-textarea
          id="input-descr"
          size="sm"
          :placeholder="$t('PAGES.SHOPITEM.DESCR')"
          v-model="local_item.descr"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group id="input-group-priority" :label="$t('PAGES.SHOPITEM.PRIORITY')" label-for="input-priority">
        <b-form-input
          id="input-priority"
          size="sm"
          v-model="local_item.priority"
        ></b-form-input>

        <p class="mt-4" style="color: rgb(107 107 107); font-style: italic;">{{ $t('SHOP_ITEM.PRIORITY_INFO') }}</p>
      </b-form-group>

      <h5 class="mb-8 mt-8">{{$t('PAGES.SHOPITEM.IMAGE')}}</h5>

      <p v-if="!local_item.shop_item_id" class="mt-4" style="color: rgb(107 107 107); font-style: italic;">{{ $t('SHOP_ITEM.MUST_CREATE_FIRST') }}</p>

      <div v-else>
        <b-row class="ml-1" v-if="local_item.img_url != ''">
          <b-col lg="12"
            ><b-img
              style="max-height: 300px;"
              :src="local_item.img_url"
              fluid
              thumbnail
            ></b-img
          ></b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col lg="12"
            ><b-button type="button" variant="success" @click="selectFile()"
              >{{$t('PAGES.SHOPITEM.UPLOAD_IMAGE')}}</b-button
            ></b-col
          >
        </b-row>

        <input
          hidden
          type="file"
          id="file"
          ref="file"
          v-on:change="handle_file_upload()"
          accept="image/*"
        />

      </div>


      <h5 class="mb-8 mt-8">{{$t('PAGES.SHOPITEM.PRICE_SEK')}}:</h5>

      <b-form-group :label="$t('PAGES.SHOPITEM.CURRENCY')" label-for="form-currency">
          <b-form-select
          id="form-currency"
          :options="currency_options"
          v-model="local_item.currency"
          />
      </b-form-group>



      <b-form-group
        id="input-group-amount"
        :label="$t('PAGES.SHOPITEM.AMOUNT')"
        label-for="input-amount"
      >
        <b-form-input
          id="input-amount"
          v-model="local_item.amount"

          ></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('PAGES.SHOPITEM.VAT_PCT')" label-for="form-vat_pct">
        <b-form-select
          id="form-vat_pct"
          :options="vatPctOptions"
          v-model="local_item.vat_pct"
          @change="calc_amount_vat"
        />
      </b-form-group>

      <b-form-group id="input-group-amount_vat" :label="$t('PAGES.SHOPITEM.AMOUNT_VAT')" label-for="input-amount_vat">
        <b-form-input id="input-amount_vat" v-model="local_item.amount_vat" type="text"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-ex_vat" :label="$t('PAGES.SHOPITEM.EX_VAT')" label-for="input-ex_vat">
        <b-form-input disabled id="input-ex_vat" v-model="ex_vat" type="text"></b-form-input>
      </b-form-group>

      <h5 class="mb-8 mt-8">{{$t('PAGES.SHOPITEM.ACCOUNTING')}}</h5>

      <b-row>
        <b-col cols="6">
          <b-form-group
            id="input-group-book_acct_article"
            :label="$t('PAGES.SHOPITEM.BOOK_ACCT_ARTICLE')"
            label-for="input-book_acct_article"
          >
            <b-form-input
              id="input-book_acct_article"
              v-model="local_item.book_acct_article"
              type="text"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            id="input-group-book_acct_vat"
            :label="$t('PAGES.SHOPITEM.BOOK_ACCT_VAT')"
            label-for="input-book_acct_vat"
          >
            <b-form-input
              id="input-book_acct_vat"
              v-model="local_item.book_acct_vat"
              type="text"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">


          <b-form-group
            id="input-group-book_place"
            :label="$t('PAGES.SHOPITEM.BOOK_PLACE')"
            label-for="input-book_place"
          >
            <b-form-input
              id="input-book_place"
              v-model="local_item.book_place"
              type="text"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">

          <b-form-group
            id="input-group-book_project"
            :label="$t('PAGES.SHOPITEM.PROJECT')"
            label-for="input-book_project"
          >
            <b-form-input
              id="input-book_project"
              v-model="local_item.book_project"
              type="text"
            ></b-form-input>
          </b-form-group>

        </b-col>
      </b-row>


      <div v-if="!is_ticket">
        <h5 class="mb-8 mt-8">{{$t('PAGES.SHOPITEM.MEMBERSHIP_SETTINGS')}}</h5>

        <b-form-group
          id="send_email_template_id-group"
          label="Skicka E-post vid köp"
          label-for="send_email_template_id">
          <b-form-select
            id="send_email_template_id"
            v-model="local_item.email_template_id"
            :options="templateOptions"/>
        </b-form-group>

        <b-form-group id="member_type_id-group" :label="$t('PAGES.SHOPITEM.MEMBER_TYPE_ID')" label-for="member_type_id">
          <b-form-select
            id="member_type_id"
            v-model="local_item.member_type_id"
            :options="memberTypeOptions"/>
        </b-form-group>


        <div>
          <h5 class="mb-8 mt-8">{{ $t('SHOP_ITEM.VISIBILITY') }}</h5>

          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="checkbox checkbox-outline checkbox-success">
                <input
                  type="checkbox"
                  name=""
                  v-model="local_item.is_active"
                />
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer">{{ $t('PAGES.SHOPITEM.IS_ACTIVE') }}</span>
            </div>

            <p class="mt-4" style="color: rgb(107 107 107); font-style: italic;">{{ $t('SHOP_ITEM.IS_ACTIVE_INFO') }}</p>

          </b-form-group>

          <div class="d-flex align-items-center mb-6">
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text>
                <label class="checkbox checkbox-sm checkbox-outline checkbox-success">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    v-model="check_join[0]"
                  />
                  <span></span>
                </label>
              </b-input-group-prepend>
              <b-input-group-prepend is-text>{{$t('PAGES.SHOPITEM.MIN_AGE')}}</b-input-group-prepend>

              <b-form-input
                v-if="local_item.rules"
                min="0"
                max="200"
                placeholder="0"
                v-model="local_item.rules.age_min"
                :disabled="!check_join[0]"
              ></b-form-input>
            </b-input-group>
          </div>

          <div class="d-flex justify-content-between mb-6">
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text>
                <label class="checkbox checkbox-sm checkbox-outline checkbox-success">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    v-model="check_join[1]"
                  />
                  <span></span>
                </label>
              </b-input-group-prepend>

              <b-input-group-prepend is-text>
                {{$t('PAGES.SHOPITEM.MAX_AGE')}}
              </b-input-group-prepend>
              <b-form-input
                v-if="local_item.rules"
                min="0"
                max="200"
                placeholder="0"
                v-model="local_item.rules.age_max"
                :disabled="!check_join[1]"
              ></b-form-input>
            </b-input-group>
          </div>

          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="checkbox checkbox-outline checkbox-success">
                <input
                  type="checkbox"
                  name=""
                  v-model="local_item.is_donation"
                />
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer">{{ $t('SHOP_ITEM.IS_DONATION') }}</span>
            </div>

            <p class="mt-4" style="color: rgb(107 107 107); font-style: italic;">{{ $t('SHOP_ITEM.IS_DONATION_INFO') }}</p>
          </b-form-group>

          <b-form-group>
            <div class="d-flex align-items-center mt-6">
              <label class="checkbox checkbox-outline checkbox-success">
                <input type="checkbox" name="is_family" v-model="local_item.is_family" />
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer">{{ $t('SHOP_ITEM.IS_FAMILY') }}</span>
            </div>

          </b-form-group>

          <b-form-group>

            <div class="d-flex align-items-center mt-6">
              <label class="checkbox checkbox-outline checkbox-success">
                <input type="checkbox" name="is_default" v-model="local_item.is_default" />
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer">{{ $t('SHOP_ITEM.IS_DEFAULT', { company_name: company_name }) }}</span>
            </div>
            <p class="mt-4" style="color: rgb(107 107 107); font-style: italic;">{{ $t('SHOP_ITEM.IS_DEFAULT_INFO') }}</p>

          </b-form-group>
        </div>


        <div>
          <h5 class="mb-8 mt-8">{{ $t('SHOP_ITEM.PERIOD_TYPE') }}</h5>
          <div>
            <label v-if="periods.length === 1" class="radio radio-outline radio-success mb-4">
              <input type="radio" v-model="local_item.period_type" value="DAYS" name="type" />
              <span style="margin-right: 12px;"></span>
              {{ $t('SHOP_ITEM.DAYS') }}

            </label>
            <label v-if="periods.length > 1" class="radio radio-outline radio-success mb-4">
              <input type="radio" v-model="local_item.period_type" value="PERIODS" name="type" />
              <span style="margin-right: 12px;"></span>
              {{ $t('SHOP_ITEM.PERIODS') }}

            </label>
            <label class="radio radio-outline radio-success mb-4">
              <input type="radio" v-model="local_item.period_type" value="RANGE" name="type" />
              <span style="margin-right: 12px;"></span>
              {{ $t('SHOP_ITEM.RANGE') }}

            </label>
          </div>

          <div class="ml-0">
            <b-form-group
              id="input-group-meta-num_days"
              label="Antal dagar"
              label-for="input-meta-num_days"
              v-if="local_item.period_type === 'DAYS'"
            >
              <b-form-input
                id="input-meta-num_days"
                v-model="local_item.num_days"

              ></b-form-input>
            </b-form-group>


            <ShopItemPeriodsTable
              class="mt-8"
              ref="periodsTable"
              v-if="local_item.period_type === 'PERIODS'"
              :shop_item_id="local_item.shop_item_id"
              :period_ids="local_item.period_ids"
              @changed="selected_period_ids_changed"

            />

            <b-form-group
              id="input-group-meta-from_date"
              :label="$t('SHOP_ITEM.FROM_DATE')"
              label-for="input-meta-from_date"
              v-if="local_item.period_type === 'RANGE'"
            >
              <memlist-date-picker v-model="local_item.from_date"></memlist-date-picker>
            </b-form-group>

            <b-form-group
              id="input-group-meta-to_date"
              :label="$t('SHOP_ITEM.TO_DATE')"
              label-for="input-meta-to_date"
              v-if="local_item.period_type === 'RANGE'"
            >
              <memlist-date-picker v-model="local_item.to_date"></memlist-date-picker>
            </b-form-group>

          </div>

          <div>

            <h5 class="mb-8 mt-8">{{ $t('SHOP_ITEM.RENEWAL') }}</h5>

            <p class="mt-4" style="color: rgb(107 107 107); font-style: italic;">{{ $t('SHOP_ITEM.NEXT_ARTICLE_INFO') }}</p>

            <b-form-group
              id="next-membership-group"
              :label="$t('SHOP_ITEM.NEXT_SHOP_ITEM_ID')"
              label-for="next_shop_item_id"
            >
              <b-form-select
                id="next_shop_item_id"
                v-model="local_item.next_shop_item_id"
                :options="next_shop_item_id_options"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              id="next-period-group"
              :label="$t('SHOP_ITEM.NEXT_PERIOD_ID')"
              label-for="next_period_id"
            >
              <b-form-select
                id="next_period_id"
                v-model="local_item.next_period_id"
                :options="period_options"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              v-if="local_item.next_period_id"
              id="create-for-next-period-group"
              :label="$t('SHOP_ITEM.CREATE_FOR_NEXT')"
              label-for="create_for_next_period"
            >
              <b-form-input
                id="input-next_name"
                v-model="next_name"
              ></b-form-input>
              <b-button @click="copy_to_next_period" variant="success">{{$t('PAGES.SHOPITEM.COPY_TO_NEXT_PERIOD')}}</b-button>
            </b-form-group>
          </div>
        </div>

      </div>

      <b-row>
        <b-col class="ml-8">
          <ul>
            <li
              v-for="(error, idx) in errors"
              :key="idx"
            >
              {{ error.text }}
            </li>

          </ul>
        </b-col>

      </b-row>

      <RightSaveButton
        ref="save-button"
        :text="local_item.is_ticket ? $t('PAGES.SHOPITEM.SAVE_TICKET') : $t('PAGES.SHOPITEM.SAVE')"
        @clicked="save_data()"
      />

    </b-form>


  </div>

</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";

ul li {
  list-style: none;
  color: red;
  font-weight: bold;
}

.select2-container  {
  width: 100% !important;
}

.select2-container--default {
  width: 100% !important;
}
</style>

<script>
import 'vue-select/dist/vue-select.css';
import Select2 from 'v-select2-component';

import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import ShopItemPeriodsTable from '@/view/pages/ml/shopitem/ShopItemPeriodsTable.vue';
import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';

import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';


export default {

name: 'shop_item_editor',

components: {
  Select2,
  ShopItemPeriodsTable,
  Confirm,
  TipLabel,
  RequiredFieldsInfo,
  RightSaveButton
},

mixins: [ toasts ],

directives: {
  numeric: {
    inserted: (el) => {
      el.oninput = (ev) => {
        const value = parseFloat(ev.target.value);
        el.value = isNaN(value) ? '' : value;
      };
    }
  }
},

computed: {
  script_tag() {
    return `<iframe src="${this.donation_url}" style="width: 640px; height: 360px; border: 0;"></iframe>`
  },
  donation_url() {
    return get_base_url() + '/donation/' + this.local_item.shop_item_id;
  },
  ex_vat() {
    return (Math.round((this.local_item.amount - this.local_item.amount_vat)*100)/100.0) || 0;
  },
  company_name() {
    return this.companies.find(c => c.company_id === this.currentCompanyId).name;
  },
  ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'reg_only', 'companies']),
  period_options() {
    return this.periods.map(( item ) => ( { text: item.name, value: item.id }));
  },
  creating() {
    return !(this.local_item && this.local_item.shop_item_id);
  },
  next_shop_item_id_options() {
    return [
      {
        text: 'Ej satt',
        value: null
      },
      ...this.shop_items
        .filter(
          shop_item =>
            !shop_item.is_ticket &&
            (!shop_item.is_days ? shop_item.shop_item_id !== this.local_item.shop_item_id : true)
        )
        .map(item => {
          return {
            text: item.name,
            value: item.shop_item_id
          };
        })
    ];
  }
},
async mounted() {
  if (this.templates) {
    this.local_templates = [ ...this.templates ];
  }
  const loader = this.$loading.show();

  if (this.item) {
    this.local_item = { ...this.item };
    this.assign_broken_fields(this.local_item);
  }

  loader && loader.hide();
},
data() {
  return {
    errors: [],
    next_name: null,
    
    local_templates: [],
    local_item: {},

    currency_options: [
      { value: 'SEK', text: 'SEK' },
      { value: 'EUR', text: 'EUR' },
      { value: 'USD', text: 'USD' }
    ],

    vatPctOptions: [
      { value: 0, text: this.$t('PAGES.SHOPITEM.VAT_FREE') },
      { value: 6, text: '6%' },
      { value: 12, text: '12%' },
      { value: 25, text: '25%' },
    ],

    check_join: [false, false],

    toDelete: null,
    apiKeyNotFound: false,
    templateOptions: [],
    myValue: '',
    myOptions: [],
    file: {
      name: 'bild0.jpg'
    },
  }
},
props: ['item','memberTypeOptions','templates','is_ticket','shop_item_id','shop_items','isTHS'],
emits: ['created', 'onCloseClicked', 'onDeleteShopItem', 'updated'],
watch: {
  templates: {
    deep: true,
    handler(val) {
      this.local_templates = [ ...val ];
    }
  },
  item: {
    deep: true,
    handler(val) {
      if (this.item) {
        this.local_item = { ...this.item }
        this.assign_broken_fields(this.local_item);
      }
    }
  },

  'local_item.name'(newValue) {
    this.next_name = newValue;
  },

  shop_item_id(newVal, oldVal) {
    if (newVal) {
      this.select_shop_item(newVal);
    }
  },
  check_join(newVal, oldVal) {
    if (!newVal[0]) this.local_item.rules.age_min = null;
    if (!newVal[1]) this.local_item.rules.age_max = null;
  },
  item_type(newVal, oldVal) {
    this.reg_only_true = newVal === 'reg_only_true';
    this.member_fee = newVal === 'member_fee';
  }
},

methods: {

  assign_broken_fields(item) {
    if (!item.rules) {
      item.rules = {
        age_min: 0,
        age_max: 999
      }
    }

    if (!item.frontend) {
      item.frontend = {
        reg_only: false
      }
    }

    if (!item.currency) {
      item.currency = 'SEK';
    }

  },

  validate_state(field, state) {
    const result = (state.dirty || state.validated) ? !state.errors[0] : null;
    return result;
  },

  async copy_to_next_period() {
    const loader = this.$loading.show();

    const copy = {};

    for (const k in this.local_item) {
      copy[k] = this.local_item[k];
    }

    copy.name = this.next_name;
    copy.period_id = this.local_item.next_period_id;
    copy.period_ids = [this.local_item.next_period_id];
    copy.next_period_id = null;
    copy.next_shop_item_id = null;
    copy.copy_from_shop_item_id = this.local_item.shop_item_id;

    if (copy.period_ids === null) {
      copy.period_ids = [];
    }

    try {
      const res = await axios.post('/shop_item', copy);
      loader && loader.hide();
      if (res.status === 201) {

        this.local_item = res.data;

        this.$emit('created', this.local_item);

        this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SHOPITEM.COPIED'));
      }
    } catch (err) {
      loader && loader.hide();
      console.error(err);
      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_COPY'));
    }
  },

  calc_amount_vat() {
    const val = parseFloat(this.local_item.amount+'');
    if (isNaN(val)) {
      this.local_item.amount_vat = 0.0;
      return;
    }

    const vat_pct = parseFloat(this.local_item.vat_pct+'');

    if (vat_pct == 0.0) {
      this.local_item.amount_vat = 0;
      return;
    }

    this.local_item.amount_vat = (Math.round((val - (val / (1 + (this.local_item.vat_pct / 100.0)))) * 100.0)) / 100.0;
  },

  async duplicate_to_all_companies() {
    const loader = this.$loading.show();

    try {
      const res = await axios.post(`/shop_item/duplicate/${this.local_item.shop_item_id}`);
      if (res.status === 201) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SHOPITEM.DUPLICATED'));
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_DUPLICATE'));
      }
      loader && loader.hide();
    } catch (err) {
      loader && loader.hide();
      console.error(err);
      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_DUPLICATE'));
    }
  },

  handle_file_upload() {
    this.file = this.$refs.file.files[0];

    if (this.file.size > (50000000)) {
      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
      return;
    }

    this.upload_file();
  },
  selectFile() {
    this.$refs.file.click();
  },
  async upload_file() {
    const loader = this.$loading.show();

    let formData = new FormData();
    formData.append('file', this.file);
    formData.append('shop_item_id', this.local_item.shop_item_id);
    formData.append('file_source', 'shop_items');
    formData.append('file_type', 'FILE_IMAGE');
    formData.append('form_shop_item_id', this.local_item.shop_item_id);
    formData.append('company_id', this.currentCompanyId);

    try {
      const res = await axios.post('/fileupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      loader && loader.hide();
      this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SHOPITEM.IMAGE_UPLOADED'));

      this.local_item.img_url = res.data.fileobjs[0].public_path;
    } catch (err) {
      loader && loader.hide();
      console.error(err);
      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_IMAGE_UPLOAD'));
    }
  },
  onCloseClicked() {
    this.$emit('onCloseClicked');
  },
  tagChanged(ee) {},
  tagSelected(ee) {},
  async loadData() {
    if (!this.currentCompanyId) {
      return;
    }
    if (!this.titles) {
      return;
    }

  },
  createShopItem() {

    this.resetForm();

    this.local_item.company_id = this.currentCompanyId;
    this.local_item.period_id = this.currentPeriodId;
    this.local_item.frontend.reg_only = true;
    this.local_item.is_ticket = this.is_ticket;
    this.local_item.is_member_fee = true;
    this.local_item.is_family = false;
    this.local_item.is_default = false;
    this.local_item.period_ids = [];

  },
  on_delete_item_confirm() {
    this.delete_shop_item(this.toDelete);
    this.toDelete = null;
  },
  deleteShopItemClicked(shop_item_id) {
    this.toDelete = shop_item_id;
    this.$refs['deleteItemConfirm'].show();
  },
  async delete_shop_item(shop_item_id) {
    try {
      const res = await axios.delete(`/shop_item/${shop_item_id}`);
      if (res.status === 204) {
        this.$emit('onDeleteShopItem', shop_item_id);
        this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SHOPITEM.DELETED'));
      } else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_DELETE'));
      }
    } catch (err) {
      console.error(err);
      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_DELETE'));
    }
  },
  async select_shop_item(shop_item_id) {
    try {
      const res = await axios.get(`/shop_item/${shop_item_id}`);

      this.local_item = res.data;

      if (this.local_item.is_days === null) {
        this.local_item.is_days = false;
      }

      this.check_join[1] = false;
      this.check_join[0] = false;

      if (this.local_item.rules) {
        if (this.local_item.rules.age_max) this.check_join[1] = true;
        if (this.local_item.rules.age_min) this.check_join[0] = true;
      } else {
        this.local_item.rules = { age_min: null, age_max: null };
      }

      if (this.local_item.period_ids === null || this.local_item.period_ids === undefined) {
        this.local_item.period_ids = [];
      }

      this.calc_amount_vat();
    } catch (err) {
      console.error(err);
      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_GET'));
    }
  },

  selected_period_ids_changed(period_ids) {
    this.local_item.period_ids = period_ids;
  },

  fixFloat(str) {
    const s = str + '';

    if (s === '') { return 0.0; }
    const r = parseFloat(s);

    if (isNaN(r)) {
      return 0.0;
    }

    return r;
  },
  fixIntWithDefault(str, val) {
    const s = str + '';

    if (str === '') { return val; }

    const r = parseInt(s);

    if (isNaN(r)) {
      return val;
    }

    return r;
  },

  get_errors() {

    let errors = [];

    if (!this.local_item.name || this.local_item.name === '' || this.local_item.name.length === 0) {
      errors.push({ text: this.$t('SHOP_ITEM.ERROR_CREATE_WITH_NAME')});
      return errors;
    }

    if (this.local_item.is_ticket) {
      // todo, validation for tickets
      return errors;
    }

    this.local_item.is_member_fee = true;

    const amount = parseInt(this.local_item.amount+'');

    if (this.local_item.is_default && (!isNaN(amount) && amount > 0)) {
      errors.push({ text: this.$t('SHOP_ITEM.ERROR_DEFAULT_MUST_NOT_COST')});

      return errors;
    }

    if (!this.local_item.period_type) {
      errors.push({ text: this.$t('SHOP_ITEM.ERROR_MEMBERSHIP_NO_PERIOD_TYPE')});

      return errors;
    }

    switch (this.local_item.period_type) {
      case 'PERIODS':
        {
          if (!this.local_item.period_ids || this.local_item.period_ids.length === 0) {
            errors.push({ text: this.$t('SHOP_ITEM.ERROR_MISSING_PERIODS')});
          }
          break;
        }
      case 'DAYS':
        if (!this.local_item.num_days || this.local_item.num_days < 0) {
          errors.push({ text: this.$t('SHOP_ITEM.ERROR_NUM_DAYS_MUST_SET')});
        }
        break;
      case 'RANGE':
        if (!dayjs(this.local_item.from_date).isValid() || !dayjs(this.local_item.to_date).isValid()) {
          errors.push({ text: this.$t('SHOP_ITEM.ERROR_INTERVAL_MUST_SET')});
        }
        else if (dayjs(this.local_item.from_date) > dayjs(this.local_item.to_date)) {
          errors.push({ text: this.$t('SHOP_ITEM.ERROR_INTERVAL_DATE_FROM_TO')});
        }
        break;
      default: {
        errors.push({ text: this.$t('SHOP_ITEM.ERROR_PERIOD_TYPE_MUST_BE_SET')});
      }
    }

    return errors;
  },

  async save_data() {

    this.errors = this.get_errors();

    if (this.errors.length !== 0) {
      this.$refs['save-button'].stop();
      console.log('errors', this.errors)
      return;
    }

    await this.update_shop_item(undefined);
  },

  async update_shop_item(e) {
    if (e) {
      e.preventDefault();
    }

    const loader = this.$loading.show();

    try {


      this.local_item.amount = this.fixFloat(this.local_item.amount);
      this.local_item.amount_vat = this.fixFloat(this.local_item.amount_vat);

      this.local_item.vat_pct = this.fixIntWithDefault(this.local_item.vat_pct, 0);
      this.local_item.book_acct_vat = this.fixIntWithDefault(this.local_item.book_acct_vat, 0);
      this.local_item.book_acct_article = this.fixIntWithDefault(this.local_item.book_acct_article, 0);

      this.local_item.next_shop_item_id = this.fixIntWithDefault(this.local_item.next_shop_item_id, null);
      this.local_item.priority = this.fixIntWithDefault(this.local_item.priority, 0);
      this.local_item.num_days = this.fixIntWithDefault(this.local_item.num_days, 0);

      this.local_item.frontend.reg_only = true;
      this.local_item.company_id = this.currentCompanyId;
      this.local_item.period_id = this.currentPeriodId;

      if (!this.local_item.currency || this.local_item.currency === '') {
        this.local_item.currency = 'SEK';
      }

      if (this.local_item.period_ids === null) {
        this.local_item.period_ids = [];
      }

      if (!this.local_item.shop_item_id) {
        try {
          const res = await axios.post('/shop_item', this.local_item);

          loader && loader.hide();

          if (res.status === 201) {

            this.local_item = res.data;

            this.$emit('created', this.local_item);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_CREATE'));
          }
        }
        catch (err) {
          console.error('error creating shop item', err);
          loader && loader.hide();
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_CREATE'));
        }

      } else {

        try {
          const res = await axios.put(`/shop_item/${this.local_item.shop_item_id}`, this.local_item);

          loader && loader.hide();

          if (res.status === 200) {

            this.local_item = res.data;
            this.$emit('updated', this.local_item);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_UPDATE'));
          }
        }
        catch (err) {
          console.error('unable to update shop item', err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_UPDATE'));
        }

      }
    }
    catch (err) {
      loader && loader.hide();
      console.error(err);
      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_UPDATE'));
    }

    this.$refs['save-button'].stop();
  },
  resetForm() {
    this.local_item = {
      vat_pct: 0,
      shop_item_id: '',
      company_id: '',
      name: '',
      descr: '',
      amount: 0,
      amount_vat: 0,
      price_sek: 0,
      vat_sek: 0,
      frontend: {
        img_url: '',
        reg_only: 0
      },
      discounts: {
        pct: 0,
        amount: 0,
        reason: ''
      },
      meta: {
        num_periods: 0,
        is_member_fee: 1,
        member_fee: false,
        send_email_template_id: null,
        periods: []
      },
      book_acct_vat: 0,
      book_acct_article: 0,
      book_place: '',
      book_project: '',
      book_tags: [],
      rules: { age_min: 0, age_max: 0 },
      is_family: false,
      is_default: false,
      is_member_fee: true,
      is_ticket: this.is_ticket,
      is_active: true,
      receiver_type: 'MEMBER',
      quantity_type:'QUANTITY',

      currency: 'SEK',

    };
  }
}
};
</script>
